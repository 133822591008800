const dev = {
  // API_ENDPOINT_URL: 'https://pem.crustycodes.com/backend/api'
  // API_ENDPOINT_URL: 'https://nsl.crustycodes.com/backend/api'
   API_ENDPOINT_URL: 'https://pem-test.crustycodes.com/backend/api'
  // API_ENDPOINT_URL: 'https://yazaki-pem.crustycodes.com/backend/api'
  // API_ENDPOINT_URL: 'https://ecosync-pem-absax.airi-sense.co.in/backend/api'
  // API_ENDPOINT_URL: "http://192.168.0.201:3001/api",
//   API_ENDPOINT_URL: 'http://www.pem.yazaki.com/api'
// API_ENDPOINT_URL: 'http://127.0.0.1:8000/api'
};

const prod = {
  // API_ENDPOINT_URL: 'https://pem.crustycodes.com/backend/api'
  // API_ENDPOINT_URL: 'https://nsl.crustycodes.com/backend/api'
   API_ENDPOINT_URL: 'https://pem-test.crustycodes.com/backend/api'
  // API_ENDPOINT_URL: 'https://yazaki-pem.crustycodes.com/backend/api'
  // API_ENDPOINT_URL: 'https://ecosync-pem-absax.airi-sense.co.in/backend/api'
  // API_ENDPOINT_URL: "http://192.168.0.201:3001/api",
//   API_ENDPOINT_URL: 'http://www.pem.yazaki.com/api'
// API_ENDPOINT_URL: 'http://127.0.0.1:8000/api'
};

const test = {
  // API_ENDPOINT_URL: 'https://pem.crustycodes.com/backend/api'
  // API_ENDPOINT_URL: 'https://nsl.crustycodes.com/backend/api'
   API_ENDPOINT_URL: 'https://pem-test.crustycodes.com/backend/api'
  // API_ENDPOINT_URL: 'https://yazaki-pem.crustycodes.com/backend/api'
  // API_ENDPOINT_URL: 'https://ecosync-pem-absax.airi-sense.co.in/backend/api'
  // API_ENDPOINT_URL: "http://192.168.0.201:3001/api",
//   API_ENDPOINT_URL: 'http://www.pem.yazaki.com/api'
  // API_ENDPOINT_URL: 'http://127.0.0.1:8000/api'

};


const getEnv = () => {
  switch (process.env.NODE_ENV) {
    case "development":
      return dev;
    case "production":
      return prod;
    case "test":
      return test;
    default:
      break;
  }
};

export const env = getEnv();
